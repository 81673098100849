import React, { Component } from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Footer from './../components/footer';
import HeroSlider from './../components/hero-slider';
import NavSection from './../components/nav-section';
import Layout from './../components/layout';
import VirtualTour from './../components/virtual-tour';

const slideImages = [
  {
    url: '/images/vidro-slider1.jpg',
  },
  {
    url: '/images/vidro-slider2.jpg',
  },
];

export class StoreSpecific extends Component {
  render() {
    const navMenus = get(this, 'props.data.allContentfulNavigation.edges');
const footer = get(this, 'props.data.allContentfulFooter.edges')

    return (
      <Layout>
        <div className="sm-transNav">
          <NavSection navMenus={navMenus} navTheme="nav-transparent" />
        </div>
        <Helmet title="Vidro" />
        <HeroSlider slides={slideImages} />
        <section className="about-room">
          <div className="container">
            <h1>Vidro</h1>
            <div className="row justify-content-between">
              <div className="col-lg-5">
                <h3 className="font-gtaM">About This Store</h3>
                <p>Premiere glassware store, Vidro, cut and colored with unique pigments and traditional techniques and imported all the way from Portugal.</p>
                <p className="mb40">
                  <b>FLOOR</b> Fourth Floor <br />
                  <b>SIZE</b> 50 sqm (97.00 x 55.50 m) <br />
                  <b>CAPACITY</b> 50 people <br />
                </p>
                <a href="https://www.vidro.com.ph/">
                  <button className="btn">Learn More</button>
                </a>
              </div>
              <div className="col-lg-6">
                <h3 className="font-gtaM">Floor Plan</h3>
                <div className="image-container">
                  <img src="/images/rue.png" alt="" className="mt-3" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <VirtualTour url="https://exsight360.com/virtual-tours/palacio-de-memoria/v8/tour.html" />
        {/* <VirtualTour url="https://palacio-virtual-tour.netlify.com/tour-13" /> */}
         <Footer 
          footer={footer}
        />
      </Layout>
    );
  }
}

export default StoreSpecific;

export const pageQuery = graphql`
  query StoreQuery($slug: String) {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulVenues(filter: {type: {eq: $slug}}) {
      edges {
        node {
          title
          slug
          header
          aboutThisRoom {
            aboutThisRoom
          }
          capacity
          floor
          size
          floorPlan {
            file {
              url
            }
          }
          slideImages {
            file {
              url
            }
          }
          virtualTourLink
          link
          linkText
        }
      }
    }
    allContentfulNavigation(sort: { fields: createdAt }) {
      edges {
        node {
          id
          slug
          pageTitle
          submenu
        }
      }
    }
    allContentfulFooter {
      edges {
        node {
          facebookLink
          footerAddress
          footerEmail
          footerHeadline
          footerImage {
            file {
              url
            }
          }
          footerLandline
          footerMobileNumber
          footerOpeningDates
          footerOpeningHours
          instagramLink
          section
        }
      }
    }
  }
`;
